const CAT_DASHBOARD = 'Dashboard';
const CAT_CAMPAIGNS = 'Campaigns';
const CAT_PRODUCTS = 'Products';
const CAT_PRODUCT_CATEGORIES = 'Product categories';
const CAT_PRODUCT_GROUPS = 'Product groups';
const CAT_BRANDS = 'Brands';
const CAT_RETAILERS = 'Retailers';
const CAT_USERS = 'Users';
const CAT_SETTINGS = 'Settings';

export const UserPermissionEnum = Object.freeze({
  // Dashboard
  VIEW_DASHBOARD: {
    category: CAT_DASHBOARD,
    label: 'View dashboard',
    value: 'view_dashboard',
  },

  // Products
  VIEW_PRODUCTS: {
    category: CAT_PRODUCTS,
    label: 'View products',
    value: 'view_products',
  },
  CREATE_PRODUCTS: {
    category: CAT_PRODUCTS,
    label: 'Create products',
    value: 'create_products',
  },
  EDIT_PRODUCTS: {
    category: CAT_PRODUCTS,
    label: 'Edit products',
    value: 'edit_products',
  },
  DELETE_PRODUCTS: {
    category: CAT_PRODUCTS,
    label: 'Delete products',
    value: 'delete_products',
  },

  // Product categories
  VIEW_PRODUCT_CATEGORIES: {
    category: CAT_PRODUCT_CATEGORIES,
    label: 'View product categories',
    value: 'view_product_categories',
  },
  CREATE_PRODUCT_CATEGORIES: {
    category: CAT_PRODUCT_CATEGORIES,
    label: 'Create product categories',
    value: 'create_product_categories',
  },
  EDIT_PRODUCT_CATEGORIES: {
    category: CAT_PRODUCT_CATEGORIES,
    label: 'Edit product categories',
    value: 'edit_product_categories',
  },
  DELETE_PRODUCT_CATEGORIES: {
    category: CAT_PRODUCT_CATEGORIES,
    label: 'Delete product categories',
    value: 'delete_product_categories',
  },

  // Product groups
  VIEW_PRODUCT_GROUPS: {
    category: CAT_PRODUCT_GROUPS,
    label: 'View product groups',
    value: 'view_product_groups',
  },
  CREATE_PRODUCT_GROUPS: {
    category: CAT_PRODUCT_GROUPS,
    label: 'Create product groups',
    value: 'create_product_groups',
  },
  EDIT_PRODUCT_GROUPS: {
    category: CAT_PRODUCT_GROUPS,
    label: 'Edit product groups',
    value: 'edit_product_groups',
  },
  DELETE_PRODUCT_GROUPS: {
    category: CAT_PRODUCT_GROUPS,
    label: 'Delete product groups',
    value: 'delete_product_groups',
  },

  // Brands
  VIEW_BRANDS: {
    category: CAT_BRANDS,
    label: 'View brands',
    value: 'view_brands',
  },
  CREATE_BRANDS: {
    category: CAT_BRANDS,
    label: 'Create brands',
    value: 'create_brands',
  },
  EDIT_BRANDS: {
    category: CAT_BRANDS,
    label: 'Edit brands',
    value: 'edit_brands',
  },
  DELETE_BRANDS: {
    category: CAT_BRANDS,
    label: 'Delete brands',
    value: 'delete_brands',
  },

  // Retailers
  VIEW_RETAILERS: {
    category: CAT_RETAILERS,
    label: 'View retailers',
    value: 'view_retailers',
  },
  CREATE_RETAILERS: {
    category: CAT_RETAILERS,
    label: 'Create retailers',
    value: 'create_retailers',
  },
  EDIT_RETAILERS: {
    category: CAT_RETAILERS,
    label: 'Edit retailers',
    value: 'edit_retailers',
  },
  DELETE_RETAILERS: {
    category: CAT_RETAILERS,
    label: 'Delete retailers',
    value: 'delete_retailers',
  },

  // Campaigns
  VIEW_CAMPAIGNS: {
    category: CAT_CAMPAIGNS,
    label: 'View campaigns',
    value: 'view_campaigns',
  },
  CREATE_CAMPAIGNS: {
    category: CAT_CAMPAIGNS,
    label: 'Create campaigns',
    value: 'create_campaigns',
  },
  EDIT_CAMPAIGNS: {
    category: CAT_CAMPAIGNS,
    label: 'Edit campaigns',
    value: 'edit_campaigns',
  },
  DELETE_CAMPAIGNS: {
    category: CAT_CAMPAIGNS,
    label: 'Delete campaigns',
    value: 'delete_campaigns',
  },
  MANAGE_CAMPAIGN_PRODUCTS: {
    category: CAT_CAMPAIGNS,
    label: 'Manage products',
    value: 'manage_campaign_products',
  },

  // Users
  VIEW_USERS: { category: CAT_USERS, label: 'View users', value: 'view_users' },
  CREATE_USERS: {
    category: CAT_USERS,
    label: 'Create users',
    value: 'create_users',
  },
  EDIT_USERS: { category: CAT_USERS, label: 'Edit users', value: 'edit_users' },
  DELETE_USERS: {
    category: CAT_USERS,
    label: 'Delete users',
    value: 'delete_users',
  },

  // Settings
  VIEW_SETTINGS: {
    category: CAT_SETTINGS,
    label: 'View settings',
    value: 'view_settings',
  },
  CREATE_SETTINGS: {
    category: CAT_SETTINGS,
    label: 'Create settings',
    value: 'create_settings',
  },
  EDIT_SETTINGS: {
    category: CAT_SETTINGS,
    label: 'Edit settings',
    value: 'edit_settings',
  },
  DELETE_SETTINGS: {
    category: CAT_SETTINGS,
    label: 'Delete settings',
    value: 'delete_settings',
  },
});

export const userPermissionList = [...Object.values(UserPermissionEnum)];

export const getPermissionListByCategory = (category) => {
  return userPermissionList.filter((el) => el.category === category);
};
