import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

import moduleAuth from './auth/moduleAuth';
import moduleProfile from './profile/moduleProfile';
import moduleDashboard from './dashboard/moduleDashboard';

Vue.use(Vuex);

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    profile: moduleProfile,
    dashboard: moduleDashboard,
  },
  strict: process.env.NODE_ENV !== 'production',
});
