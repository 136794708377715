import axios from '@/axios';
import { DashboardAction } from '@/store/actionTypes';

export default {
  [DashboardAction.fetchStatistics](context, filters = {}) {
    return new Promise((resolve, reject) => {
      return axios
        .post('/statistics/campaigns', filters)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [DashboardAction.fetchTopRoiList](context) {
    return new Promise((resolve, reject) => {
      return axios
        .get('/statistics/top-roi-list')
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [DashboardAction.fetchTopRoasList](context) {
    return new Promise((resolve, reject) => {
      return axios
        .get('/statistics/top-roas-list')
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
};
