import Vue from 'vue';
import MathUtil from '@/utils/MathUtil';

Vue.filter('title', (value, replacer = '_') => {
  if (!value) return '';

  return value
    .toString()
    .split(replacer)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
});

Vue.filter('percentage', (value) => {
  return `${MathUtil.round(value * 100)}%`;
});

Vue.filter('numInt', (value) => {
  return Math.round(value);
});

Vue.filter('formatDate', (value) => {
  const date = new Date(value);
  const year = date.getFullYear();
  const day = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;
  const month =
    date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const time = date.toLocaleTimeString();

  return `${year}-${month}-${day} ${time}`;
});
