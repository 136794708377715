export const UserRoleEnum = Object.freeze({
  ALL: { label: 'All', value: 'all' },
  SYSADMIN: { label: 'SysAdmin', value: 'SYSADMIN' },
  GLOBAL: { label: 'Global Manager', value: 'GLOBAL' },
  REGION: { label: 'Region Manager', value: 'REGION' },
  COUNTRY: { label: 'Country Manager', value: 'COUNTRY' },
  KAM: { label: 'Key Account Manager', value: 'KAM' },
});

export const userRoleList = [...Object.values(UserRoleEnum)];

export const getRoleByValue = (val = null) => {
  if (val === null) return '';

  return userRoleList.find((el) => el.value === val);
};

export const getRoleName = (val = null) => {
  if (val === null) return '';

  return getRoleByValue(val).label;
};
