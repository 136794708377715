import Vue from 'vue';
import App from '@/App';

// Vuesax Component Framework
import Vuesax from 'vuesax';
import 'material-icons/iconfont/material-icons.css'; // Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax

// Gate
import Gate from '@/gate';

// API Calls
import '@/http/requests';

// Theme Configurations
import '../themeConfig';

// Globally Registered Components
import '@/globalComponents';

// Styles: SCSS
import '@/assets/scss/main.scss';

// Tailwind
import '@/assets/css/main.css';

// Vue Router
import router from '@/router';

// Vuex Store
import store from '@/store/store';

// i18n
import i18n from '@/i18n/i18n';

// Filters
import '@/filters/filters';

// Global Mixins
import '@/mixins/globalMixin';

// VeeValidate
import VeeValidate from 'vee-validate';

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer';

// Vue select css
// Note: In latest version you have to add it separately
import 'vue-select/dist/vue-select.css';

Vue.use(Vuesax);

Vue.prototype.$gate = new Gate();
Vue.use(VeeValidate);
Vue.use(VueHammer);

// Feather font icon
require('./assets/css/iconfont.css');

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
