export const getMessageFromError = ({ response, message }) => {
  if (!response) {
    return;
  }

  const { data } = response;
  let errorMessage = data.error || message;

  if (data.errors) {
    const firstErrorKey = Object.keys(data.errors)[0];
    errorMessage = data.errors[firstErrorKey][0];
  }

  return errorMessage;
};

export const disableLoader = () => {
  const appLoading = document.getElementById('loading-bg');

  if (appLoading) {
    appLoading.style.display = 'none';
  }
};

export const setPageTitle = (to) => {
  const title = process.env.VUE_APP_TITLE;

  if (to.meta && to.meta.title) {
    document.getElementsByTagName(
      'title'
    )[0].innerHTML = `${to.meta.title} | ${title}`;
  } else {
    document.getElementsByTagName('title')[0].innerHTML = title;
  }
};
