import { UserPermissionEnum } from '@/enums/UserPermissionEnum';

const userInfoKey = 'userInfo';
const tokenExpiryKey = 'tokenExpiry';

class AuthService {
  get isAuthenticated() {
    const { isExpiryValid, isUserValid } = this;
    const isAuthenticated = isExpiryValid && isUserValid;

    return isAuthenticated;
  }

  get isExpiryValid() {
    const isExpired =
      new Date(Date.now()) <
      new Date(parseInt(localStorage.getItem(tokenExpiryKey)));

    return isExpired;
  }

  get isUserValid() {
    return (
      Boolean(localStorage.getItem(userInfoKey)) &&
      Boolean(JSON.parse(localStorage.getItem('userInfo')).id)
    );
  }

  getUser() {
    return JSON.parse(localStorage.getItem(userInfoKey));
  }

  isFirstLogin() {
    return this.getUser() ? this.getUser().is_first_login : false;
  }

  getUserRole() {
    return this.getUser() ? this.getUser().role : null;
  }

  getUserPermissions() {
    return this.getUser() ? this.getUser().permission_names : null;
  }

  getDefaultLoginRoute() {
    const permissions = this.getUserPermissions();

    if (!this.getUser()) {
      return '/login';
    }

    if (permissions.includes(UserPermissionEnum.VIEW_DASHBOARD.value)) {
      return '/dashboard';
    }

    if (permissions.includes(UserPermissionEnum.VIEW_CAMPAIGNS.value)) {
      return '/campaigns';
    }

    if (permissions.includes(UserPermissionEnum.VIEW_PRODUCTS.value)) {
      return '/products';
    }

    if (permissions.includes(UserPermissionEnum.VIEW_PRODUCT_CATEGORIES.value)) {
      return '/product-categories';
    }

    if (permissions.includes(UserPermissionEnum.VIEW_BRANDS.value)) {
      return '/brands';
    }

    if (permissions.includes(UserPermissionEnum.VIEW_RETAILERS.value)) {
      return '/retailers';
    }

    if (permissions.includes(UserPermissionEnum.VIEW_USERS.value)) {
      return '/users';
    }

    return '/user-profile';
  }
}

export default new AuthService();
