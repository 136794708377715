import axios from '@/axios';
import { ProfileAction } from '@/store/actionTypes';
import { AuthMutation } from '@/store/mutationTypes';

const handleUploadPhoto = (method, resolve, reject, commit, file = null) => {
  return axios[method]('/profile/photo', file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(({ data }) => {
      commit(AuthMutation.UPDATE_USER_INFO, data.userData, {
        root: true,
      });
      resolve(data);
    })
    .catch(reject);
};

export default {
  [ProfileAction.changePassword](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post('/profile/change-password', item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [ProfileAction.updateProfile]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put('/profile', item)
        .then(({ data }) => {
          commit(AuthMutation.UPDATE_USER_INFO, data.userData, {
            root: true,
          });
          resolve(data);
        })
        .catch(reject);
    });
  },
  [ProfileAction.uploadPhoto]({ commit }, photoFile) {
    return new Promise((resolve, reject) => {
      return handleUploadPhoto('post', resolve, reject, commit, photoFile);
    });
  },
  [ProfileAction.removePhoto]({ commit }) {
    return new Promise((resolve, reject) => {
      return handleUploadPhoto('delete', resolve, reject, commit);
    });
  },
};
