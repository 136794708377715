import { UserRoleEnum } from '@/enums/UserRoleEnum';

class Gate {
  get user() {
    return JSON.parse(localStorage.getItem('userInfo'));
  }

  get permissions() {
    return this.user.permission_names;
  }

  get isKamRole() {
    return this.user.role === UserRoleEnum.KAM.value;
  }

  get isCountryRole() {
    return this.user.role === UserRoleEnum.COUNTRY.value;
  }

  can(permissions = []) {
    if (typeof permissions === 'string') {
      return this.permissions.includes(permissions);
    }

    return permissions.some((el) => this.permissions.includes(el));
  }
}

export default Gate;
